ion-content,
ion-list,
ion-menu-toggle {
  --background: rgb(245, 245, 245) !important;
  background-color: rgb(245, 245, 245) !important;
}

ion-item-divider {
  --background: #222428;
  color: whitesmoke;

  ion-label {
    color: whitesmoke;

    ion-searchbar {
      --background: whitesmoke !important;
    }
  }
}

ion-item {
  --background: white;
}

ion-segment-button {
  --background: whitesmoke;
  --background-checked: white;
  --color-checked: black;
}

ion-tab-button {
  --ripple-color: gray;

  ion-label {
    color: gray;
  }
}

ion-spinner {
  --color: #222428;
}

ion-footer {
  background-color: whitesmoke;

  ion-toolbar {
    --background: whitesmoke;
  }
}

ion-icon {
  color: #717171;
}

.my-chat {
  margin: 0px !important;
  --background: #363636;

  ion-item {
    --background: #363636;

    ion-input {
      --padding-start: 20px;
      --placeholder-color: gray;
      --background: white;
      border-radius: 10px;
      border-color: red;
      color: black;
    }

    ion-button {
      ion-icon {
        color: white;
      }
    }
  }
}

.other-message {
  background: #4c8dff;
  color: #fff;
}

.my-message {
  background: #50c8ff;
  color: #fff;
}

.alert-delete {
  //background: no-repeat;
  //background-image: url('/assets/images/delete-account.png');
  //background-size: 100% 100%;
  background-color: #1e1e1e !important;

  button.alert-button:nth-child(2) {
    background-color: red;
    color: whitesmoke;
    font-weight: bold;
  }
}

.md .dark-theme {
  --ion-color-primary: #428cff;
  --ion-color-primary-rgb: 66, 140, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3a7be0;
  --ion-color-primary-tint: #5598ff;

  --ion-color-secondary: #50c8ff;
  --ion-color-secondary-rgb: 80, 200, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #46b0e0;
  --ion-color-secondary-tint: #62ceff;

  --ion-color-tertiary: #6a64ff;
  --ion-color-tertiary-rgb: 106, 100, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #5d58e0;
  --ion-color-tertiary-tint: #7974ff;

  --ion-color-success: #29c467cb;
  --ion-color-success-rgb: 47, 223, 117;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #29c467cb;
  --ion-color-success-tint: #44e283c9;

  --ion-color-warning: #ffd534;
  --ion-color-warning-rgb: 255, 213, 52;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0bb2e;
  --ion-color-warning-tint: #ffd948;

  --ion-color-danger: #ff4961;
  --ion-color-danger-rgb: 255, 73, 97;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e04055;
  --ion-color-danger-tint: #ff5b71;

  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244, 245, 248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #222428;
  --ion-color-light-rgb: 34, 36, 40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;

  --ion-background-color: #121212;
  --ion-background-color-rgb: 18, 18, 18;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: #1e1e1e;
  --ion-item-background-color: #1e1e1e;

  --ion-toolbar-background: #1f1f1f;

  --ion-tab-bar-background: #1f1f1f;

  --ion-card-background: #1e1e1e;

  ion-content,
  ion-list,
  ion-menu-toggle {
    --background: #1e2023 !important;
    background-color: #1e2023 !important;
  }

  ion-item {
    --background: #1e1e1e;
  }

  ion-segment-button {
    --background: #1e2023;
    --background-checked: #1e1e1e;
    --color-checked: whitesmoke;
  }

  ion-icon {
    color: white;
  }

  ion-tab-button {
    --ripple-color: black;

    ion-label {
      color: whitesmoke;
    }
  }

  ion-spinner {
    --color: #f5f6f9;
  }

  ion-content {
    --background: #222428;
  }

  ion-list {
    background-color: #222428;
  }

  ion-radio {
    --color-checked: #f5f6f9;
  }

  ion-toggle {
    --background-checked: #323539;
    --handle-background-checked: #f5f6f9;
  }

  ion-checkbox {
    --border-color-checked: #f5f6f9;
    --background-checked: #222428;
  }

  ion-item-divider {
    ion-label {
      color: whitesmoke;

      ion-searchbar {
        --background: #363636 !important;
      }
    }
  }

  ion-footer {
    background-color: #222428;

    ion-toolbar {
      --background: #222428;
    }
  }

  .my-chat {
    margin: 0px !important;
    --background: black;

    ion-item {
      --background: black;

      ion-input {
        --padding-start: 20px;
        --placeholder-color: gray;
        --background: white;
        border-radius: 10px;
        color: black;
      }

      ion-button {
        ion-icon {
          color: white;
        }
      }
    }
  }

  .other-message {
    background: #222428;
    color: #fff;
  }

  .my-message {
    background: #383a3e;
    color: #fff;
  }
}