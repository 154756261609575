/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import '~swiper/css';
@import '~swiper/css/bundle';

* {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

ion-content {

  ion-list,
  ion-grid,
  ion-card {
    margin: auto !important;
    max-width: 800px !important;
  }

  ion-item {
    max-width: 800px !important;
  }
}

ion-header,
ion-footer {
  ion-list {
    margin: auto !important;
    max-width: 800px !important;
  }

  ion-toolbar {

    ion-item,
    ion-searchbar,
    ion-title,
    ion-button,
    on-accordion-group,
    ion-accordion,
    ion-card {
      margin: auto !important;
      max-width: 800px !important;
    }
  }
}

ion-accordion {
  background-color: transparent !important;
  --background: transparent !important;
}

swiper {
  max-width: 800px !important;
  max-height: 220px !important;
  border-radius: 7px;

  img {
    width: auto; //391px
    max-height: 220px !important;
    border-radius: 7px;
    object-fit: cover;
    object-position: center;
  }
}

.badge-icon {
  position: absolute;
  right: -10px;
  top: -5px;
  transform: scale(0.8);
}

.item-hover {
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.background-shadow {
  background-color: rgba(0, 0, 0, 0.7);
}

section {
  display: flex;
  justify-content: center !important;
  align-content: center !important;

  ion-button {
    width: 100% !important;
  }

  margin-bottom: 5px;
}

.rating-modal {
  --border-radius: 10px;
  --height: 300px;
  --width: 400px;
  //display: block;
}

ion-footer {
  ion-toolbar {
    margin-bottom: 10px;
  }
}

ion-item {
  border-radius: 10px;
  margin-bottom: 10px;
}

ion-thumbnail {
  --border-radius: 8px;
  --size: 80px;
}

ion-segment-button,
ion-button {
  text-transform: none;
}

ion-list {
  margin: 10px !important;

  ion-item-divider {
    border-radius: 10px;
    margin-bottom: 10px;
  }

  ion-item-sliding {
    border-radius: 10px;
    margin-bottom: 10px;

    ion-item {
      margin-bottom: 0px;
    }
  }

}

.steps-modal {
  --max-height: 640px;
  --max-width: 95%;
  --border-radius: 10px;
}

.steps-redirect-modal {
  --max-height: 80%;
  --max-width: 95%;
  --border-radius: 10px;
}

.my-loading-spinner {
  position: fixed;
  z-index: 999;
  height: 5em;
  width: em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transform: scale(2.5);
}

.blob {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(0.95);
  animation: pulse 2.5s infinite;
  z-index: 99;
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(0.95);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.animate-top {
  position: relative;
  animation-name: animatetop;
  animation-duration: 1s
}

@keyframes animatetop {
  from {
    top: -100px;
    opacity: 0
  }

  to {
    top: 0;
    opacity: 1
  }
}

.animate-right {
  position: relative;
  animation-name: animateright;
  animation-duration: 1s;
  opacity: 0.9;
}

@keyframes animateright {
  from {
    right: -150px;
    opacity: 0
  }

  to {
    right: 0;
    opacity: 0.9;
  }
}

.animate-left {
  position: relative;
  animation-name: animateleft;
  animation-duration: 1s;
  opacity: 0.9;
}

@keyframes animateleft {
  from {
    left: -150px;
    opacity: 0
  }

  to {
    left: 0;
    opacity: 0.9;
  }
}

.left-to-right {
  position: relative;
  animation: linear infinite;
  animation-name: leftToRight;
  animation-duration: 1.3s;
}

@keyframes leftToRight {
  0% {
    left: 0;
  }

  50% {
    left: 10px;
  }

  100% {
    left: 0;
  }
}

.right-to-left {
  position: relative;
  animation: linear infinite;
  animation-name: rightToLeft;
  animation-duration: 1.3s;
}

@keyframes rightToLeft {
  0% {
    right: 0;
  }

  50% {
    right: 10px;
  }

  100% {
    right: 0;
  }
}

@keyframes changeColors {

  0%,
  100% {
    --background: white;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }

  50% {
    --background: #fff9e4;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.3);
  }
}

.change-colors {
  animation: ease-in-out infinite;
  animation-name: changeColors;
  animation-duration: 3s;
}

@keyframes flipHoriz {

  0%,
  100% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(-20px);
  }
}

.flip-horiz {
  animation: flipHoriz 2s ease-in-out infinite;
}